<template>
  <v-app :style="inlineBgImage()">
    <v-container>
      <v-slide-y-transition>
        <mex-home-card :useHeaderTitle="false" headerColor="transparent" headerImage="LicenseServer_Title.png" style="margin-top: 200px">
          <template v-slot:headerImage>
            <v-img :src="require(`../assets/NotFound.png`)" height="550" style="z-index: 0" />
          </template>
          <template v-slot:reveal-actions>
            <v-row align="center" justify="center">
              <mex-btn class="ma-2" content="Go Back" icon="mdi-arrow-left" @click="goBack" />
              <mex-btn class="ma-2" content="Go Home" icon="mdi-home" @click="goToHome" />
            </v-row>
          </template>
        </mex-home-card>
      </v-slide-y-transition>
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NotFound',
  components: {
    MexHomeCard: () => import('../components/MedITEX_Vue_Components/MexComponents/MexHomeCard'),
  },
  computed: {
    ...mapGetters('theme', ['getDarkModeSetting', 'getActTheme']),
    getTheme() {
      return this.getDarkModeSetting ? 'dark' : 'light';
    },
  },
  created() {
    this.$vuetify.theme.themes = this.getActTheme;
    this.$vuetify.theme.dark = this.getDarkModeSetting;
  },
  methods: {
    goToHome() {
      this.$router.push('/');
    },
    goBack() {
      this.$router.go(-1);
    },
    inlineBgImage() {
      // eslint-disable-next-line global-require
      const bgImage = require('../assets/Lizenzserver_Backround_only.png');
      return (
        ` backgroundImage: url("${bgImage}"); ` +
        ` background-size: cover;` /* for IE9+, Safari 4.1+, Chrome 3.0+, Firefox 3.6+ */ +
        `-webkit-background-size: cover;` /* for Safari 3.0 - 4.0 , Chrome 1.0 - 3.0 */ +
        `-moz-background-size: cover;` /* optional for Firefox 3.6 */ +
        `-o-background-size: cover;` /* for Opera 9.5 */ +
        `margin: 0;` /* to remove the default white margin of body */ +
        `padding: 0;` /* to remove the default white margin of body */ +
        `overflow: hidden;`
      );
    },
  },
};
</script>
